.spinner-modal-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #fff;
  padding: 20px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: 140px;
  width: 140px;
  border-radius: 30px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border: 0;
  outline: transparent;
}

@media (max-width: 290px) {
  .spinner-modal-responsive {
    width: 90% !important;
  }
}

.react-tour-modal {
  background-color: #fff;
  position: absolute;
}

@media (max-width: 400px) {
  .reactour__helper {
    max-width: 297px !important;
  }
}

@media (max-width: 358px) {
  .react-tour-modal {
    width: 258px !important;
  }
}

@media (max-width: 312px) {
  .reactour__helper {
    max-width: 259px !important;
  }
}

.ant-modal-content {
  border-radius: 31px !important;
}

.ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-info-solicitation-responsive {
  position: absolute;
  background-color: #fff;
  padding: 20px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  width: 460px;
  border-radius: 30px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border: 0;
  outline: transparent;
}

@media (max-width: 1024px) {
  .modal-info-solicitation-responsive {
    top: 44%;
  }
}

@media (max-width: 520px) {
  .modal-info-solicitation-responsive {
    width: 90%;
  }
}

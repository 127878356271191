.mestres-modal .ant-modal-content {
  border-radius: 31px !important;
  width: 320px;
  height: 300px;
  text-align: center;
}

@media screen and (max-width: 720px) {
  .mestres-modal .ant-modal-content {
    width: 100%;
  }
}

.mestres-modal .ant-modal {
  width: 320px !important;
}

@media screen and (max-width: 720px) {
  .mestres-modal .ant-modal {
    width: 100%;
  }
}

.mestres-modal .ant-modal-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
/*# sourceMappingURL=modal-to-responsive.css.map */
.terms-and-conditions-modal-responsive {
  position: absolute;
  background-color: #fff;
  padding: 20px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: 500px;
  width: 540px;
  border-radius: 30px;
  box-shadow: 0px 0px 6px 0px lightgrey;
  border: 0;
  outline: transparent;
}

@media (max-width: 580px) {
  .terms-and-conditions-modal-responsive {
    width: 90% !important;
  }
}

@media (max-height: 520px) {
  .terms-and-conditions-modal-responsive {
    height: 460px;
  }
}
